<script setup lang="ts">
import { useUserState } from '~/composables/useUserState';

type Props = {
  redirect?: string;
};
const props = withDefaults(defineProps<Props>(), {
  redirect: '/',
});
const { isOniModeReleased: _isOniModeReleased } = useUserState();

const { push } = useRouter();
const isOniModeReleased = ref<boolean | undefined>(undefined);

onBeforeMount(async () => {
  isOniModeReleased.value = _isOniModeReleased.value;
  if (!isOniModeReleased.value) {
    await push(props.redirect);
  }
});
</script>

<template>
  <div v-if="isOniModeReleased">
    <slot />
  </div>
</template>
